import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "antd";
import { string, shape, arrayOf, bool, array, func, oneOf } from "prop-types";
import Title from "antd/es/typography/Title";
import axios from "axios";
import Cookies from "js-cookie";
import { navigate } from "gatsby";
import check from "../../images/svg/checkmark.svg";
import FormItemsBuilder from "../Forms/FormItems/FormItemsBuilder";
import chevron from "../../images/svg/chevron_left.svg";
import {
  formItemVisibilityEventName,
  hubSpotFormSubmitBaseUrl,
} from "../../constants";
import GatedStyles from "./GatedStyles";
import Image from "../ImageQuerys/LargeFeaturesImages";
import { createFormDataToSend } from "../../utils/form-utils";
import { submitForm, GATED_ASSETS } from "../../utils/firebase/forms";
import { getThankYouUrl } from "../../utils/url-utils";

export default function GatedForm(props) {
  const {
    formId,
    formTitle,
    formItems,
    bgImage,
    pageSubtext,
    formDataToSend,
    initialValues,
    toggleVisibilityMap,
    onValuesChange,
    topImg,
    thankYouPath,
    gatedCampaign,
    btnText,
    withLineOfBusinessOption,
    lineOfBusiness,
  } = props;

  console.log("LINE OF BUSINESS: ", lineOfBusiness);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(formItems);

  const visibilityListener = (e) => {
    const { key, value } = e.detail;
    const toggle = toggleVisibilityMap.find((item) => item.changeOnKey === key);

    if (toggle) {
      const newData = data.map((item) => {
        if (item.name === toggle.togglesItem) {
          return { ...item, visible: toggle.changeOnValue === value };
        }

        return item;
      });
      setData(newData);
    }
  };

  useEffect(() => {
    setData(formItems);
  }, [formItems]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(formItemVisibilityEventName, visibilityListener);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener(
          formItemVisibilityEventName,
          visibilityListener
        );
      }
    };
  });

  const onFinish = async (values) => {
    setLoading(true);
    // const address = zipCodesMap.get(values.zip);

    const dataToSend = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      company: values.company,
      // city: address.city,
      // state: address.state,
      zip: values.zip,
      line_of_business: withLineOfBusinessOption
        ? values.industry
        : lineOfBusiness,
      business_type: values.business_type || "",
      conversion_page: window.location.pathname,
      submitted_on: new Date(),
      product_interest: window.location.pathname,
      get_demo: values.get_demo || false,
      gclid: Cookies.get("gclid") || "",
      gated_campaign: gatedCampaign || "",
      utm_source: Cookies.get("utmSource") || "organic",
      utm_medium: Cookies.get("utmMedium") || "",
      utm_campaign: Cookies.get("utmCampaign") || "",
      utm_content: Cookies.get("utmContent") || "",
      utm_term: Cookies.get("utmTerm") || "",
      heap_userId: window?.heap?.userId || "",
    };

    const result = await submitForm(dataToSend, GATED_ASSETS);

    if (result) {
      setLoading(false);
      window.sessionStorage.setItem("Name", values.firstName);
      const thankYouUrl =
        typeof window !== "undefined"
          ? getThankYouUrl(window.location.pathname)
          : null;

      if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
        // eslint-disable-next-line no-undef
        dataLayer.push({ event: `gated-asset-lead` });
      }

      if (typeof window !== "undefined" && typeof heap !== "undefined") {
        // eslint-disable-next-line no-undef
        heap.track("gated_asset", {
          traffic_source: Cookies.get("utmSource") || "organic",
          conversion_path: window.location.pathname,
        });
      }

      navigate(thankYouUrl || thankYouPath);
    } else {
      setLoading(false);
    }
  };

  return (
    <section className="demo" style={{ marginTop: 0, padding: 8 }}>
      <Row gutter={[32, 0]} className="fixed-padding" align="middle">
        <Col lg={24} sm={24}>
          <div className="asset-form-wrapper">
            {topImg && <Image className="asset-download" imageName={topImg} />}
            <div className="f">
              <Title level={2} className="text-center whitespace-pre-line">
                {formTitle}
              </Title>
              {pageSubtext && (
                <p
                  style={{
                    color: "#748093",
                    textAlign: "center",
                    marginBottom: 16,
                  }}
                >
                  {pageSubtext}
                </p>
              )}
              <Form
                name={formTitle}
                layout="vertical"
                initialValues={{
                  remember: false,
                  ...initialValues,
                }}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
              >
                <Row gutter={[16, 0]}>
                  {formItems.map((item, idx) => (
                    <Col
                      lg={item.itemType === "checkbox" ? 24 : 12}
                      xs={24}
                      key={idx.toString()}
                    >
                      <FormItemsBuilder data={[item]} />
                    </Col>
                  ))}
                </Row>
                <div className="form-btn-cont">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="cta-primary mobile-full"
                      style={{ marginTop: 20 }}
                      loading={loading}
                      size="small"
                    >
                      {btnText || "Get my copy"}
                      <img
                        src={chevron}
                        alt="chevron icon"
                        style={{ margin: "0px 5px 2px 10px" }}
                      />
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <GatedStyles />
    </section>
  );
}

GatedForm.propTypes = {
  formId: string,
  pageTitle: string,
  formTitle: string,
  pageSubtext: string,
  btnText: string,
  // eslint-disable-next-line react/forbid-prop-types
  formItems: array,
  // eslint-disable-next-line react/forbid-prop-types
  shortFormDataToSend: array,
  // eslint-disable-next-line react/forbid-prop-types
  formDataToSend: array,
  featureTitle: string,
  featureSubtext: string,
  // eslint-disable-next-line react/forbid-prop-types
  blockList: array,
  gatedCampaign: string.isRequired,
  thankYouPath: string,
  bgImage: string,
  toggleVisibilityMap: arrayOf(
    shape({
      changeOnKey: string,
      changeOnValue: string,
      togglesItem: string,
    })
  ),
  initialValues: shape({}),
  onValuesChange: func,
  topImg: string,
  withLineOfBusinessOption: bool,
  lineOfBusiness: oneOf(["Venues", "Hospitality", "Retail & Services"]),
};

GatedForm.defaultProps = {
  formId: "",
  pageTitle: "",
  formTitle: "",
  btnText: "",
  pageSubtext: "",
  shortFormDataToSend: [],
  formItems: [],
  formDataToSend: [],
  featureTitle: "",
  featureSubtext: "",
  blockList: [],
  bgImage: "",
  initialValues: {},
  toggleVisibilityMap: [
    {
      changeOnKey: "industry",
      changeOnValue: "Other",
      togglesItem: "business_type",
    },
  ],
  thankYouPath: "/gated-assets/thank-you",
  onValuesChange: () => true,
  topImg: "",
  withLineOfBusinessOption: false,
  lineOfBusiness: "Retail & Services",
};
